@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.woff");
  font-style: normal;
}
@font-face {
  font-family: "Inter-Light";
  src: url("../fonts/Inter/Inter-Light.woff");
  font-style: normal;
}
@font-face {
  font-family: "Inter-extraLight";
  src: url("../fonts/Inter/Inter-ExtraLight.woff");
  font-style: normal;
}
@font-face {
  font-family: "Inter-Thin";
  src: url("../fonts/Inter/Inter-Thin.woff");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("../fonts/Inter/Inter-Medium.woff");
}
@font-face {
  font-family: "Inter-Black";
  src: url("../fonts/Inter/Inter-Black.woff");
}
@font-face {
  font-family: "Inter-semiBold";
  src: url("../fonts/Inter/Inter-SemiBold.woff");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../fonts/Inter/Inter-Bold.woff");
}
@font-face {
  font-family: "Inter-extraBold";
  src: url("../fonts/Inter/Inter-ExtraBold.woff");
}

@font-face {
  font-family: "Grandis";
  src: url("../fonts/Grandis/Grandis-Regular.woff");
}
